img, .sliding-background, .App {
  image-rendering: auto;
  image-rendering: crisp-edges;
  image-rendering: pixelated;

  /* Safari seems to support, but seems deprecated and does the same thing as the others. */
  image-rendering: -webkit-optimize-contrast;
}

#app-root {
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding-top: 1vh;
  box-sizing: border-box;
  background-color: #35393d;
}

#app-root>[id*="-box"] {
  box-sizing: border-box;
  margin: 0;
  overflow: hidden;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

#background-box {
  width: 174.2146vh;
  height: 98vh;
  background-repeat: 'no-repeat';
  background-size: cover;
  border-radius: 1%;
}

#background-box * {  
  user-select: none;
}

.scale-box {
  width: 100%;
  height: 130%;
  max-width: 100vw;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

#content-box {
  width: 100%;
  height: 100%;
  max-width: 174.2146vh;
  max-height: 98vh;
}

img.background {
  position: absolute;
  height: 100%;
  width: auto;
  left: 50%;
  top: 0%;
  transform: translate(-50%, 0);
}

.foreground {
  position: absolute;
  height: 100%;
  width: auto;
}

.footer {
  width: 100%;
  height : 32px;
  overflow: visible;
  position: absolute;
  bottom: 0;
  padding : 5px;
  display: inline-block;
  line-height: 32px;
}

.copyright {
  float: left;
  color : white;
}

.container {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.sliding-background {  
  height: 100%;
  width: 1000%;
  background-size: auto 100%;
  background-repeat: repeat-x;
  animation: slide 60s linear infinite;
}

@keyframes slide{
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-1692px, 0, 0);
  }
}
.site-summary {
    width: 60%;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    overflow: visible;
}

.description {
    background-color: #f0f8ff60;
    border-radius: 10px;
    padding: 10px;
    font-size: 2vh;
    font-weight: bold;
    color: white;
    text-shadow: 1px 1px 2px black;
}

.logo {
    width: 40vh;
    min-width: 288px;
}

div.outer {
    width: 100%; /* some width amount needed */
    min-height: 200px;
    margin: 0 auto; 
    overflow: visible;
}

div.inner {
    position:relative;
    float: right; /* this was added and display removed */
    right: 50%;
}

div.inner img {
    position: relative; 
    right:-50%; /* this was changed from "left" in original */
}
.socials-bar {
    margin-top: 5px;
    border-radius: 5px;
    padding: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
}

.socials-bar a,
.socials-bar a:visited,
.socials-bar a:hover,
.socials-bar a:active {
    color: inherit;
    text-decoration: none;
}

.social-link {
    padding: 0 10px;
    text-align: center;
    filter: drop-shadow(1px 1px 1px black);
    color :white;
}

.social-link {
    font-size: 24px;
    font-weight: bold;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #8a8a8a;
}

.social-link .icon {
    font-size: 70px;
}

.text { color: white;}
a.text,
a.text:visited,
a.text:hover,
a.text:active {
    color: white;
    text-decoration: none;
}

.twitter {
    color: rgb(98, 187, 232);
}
.steam {
    color: white;
}
.bluesky {
    color: #0070ff;
}

path {
    stroke: #646464;
    stroke-width: 9px;
  }

  
.bluesky path{
    stroke-width: 1px;
}
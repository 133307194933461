.kerrie-link {
    width: 275px;
    height: 32px;
    line-height: 32px;
    float: right;
    right: 0;
    margin-right : 5px;
    text-align: right;
}

.kerrie-img{
    width: 32px;
    border-radius: 50%;
    float: right;
    margin: 0 5px;
}